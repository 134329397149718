import React from 'react'
import { Link } from 'gatsby'
import Helmet from 'react-helmet'
import Layout from '../components/layout'
import BannerLanding from '../components/BannerLanding'
import { isLoggedIn } from "../services/auth"
import Login from './login'

import pic01 from '../assets/images/instructions-table-grapes.jpg'

const TableGrapes = (props) => {
    if (!isLoggedIn()) {
        return <Login />
    }
    return (
        <Layout>
            <Helmet>
                <title>Table Grapes - Moonlight</title>
                <meta name="description" content="Table Grapes" />
            </Helmet>

            <BannerLanding
                bannerStyle="style2"
                headerText="Red, Green and Black Seedless Grapes"
                descriptionText="each cluster full of beautiful plump fruit just exploding with flavor."
            />

            <div id="main">
                <section id="one">
                    <div className="inner">
                        <header className="major">
                            <h2>A Fun and Healthy Treat for the Whole Family</h2>
                        </header>
                        <p>Moonlight table grapes come from vines in the San Joaquin Valley—the grape capital of the world. Picture endless rows of lush vineyards filled with bunches upon bunches of Moonlight table grapes. That’s our idea of heaven.</p>
                        <p>Moonlight table grapes are available from July to December.</p>
                    </div>
                </section>
                <section id="two" className="spotlights">
                    <section>
                        <div className="image">
                            <img src={pic01} alt="" />
                        </div>
                        <div className="content">
                            <div className="inner">
                                <header className="major">
                                    <h3>IN GRAPE SHAPE!</h3>
                                </header>
                                <p>A SIMPLE GUIDE TO BUYING, STORING, AND EATING TABLE GRAPES:</p>
                                <h5>Choose Wisely</h5>
                                <p>
                                    Look for clusters of grapes with green, pliable stems and plump berries. Don’t dismiss the grapes with a powdery white coating on them. That’s called bloom and it helps protect the berries from moisture and decay.
                            </p>
                                <p>
                                    During your grape selection process you’ll be faced with a decision. Do you go green, red, or blue-black? Hey, why not try them all? You really can’t go wrong.
                            </p>
                                <h5>Storing Table Grapes</h5>
                                <p>
                                    Grapes are a hearty fruit that can keep for up to two weeks when handled properly. Follow these simple steps and you’ll be able to enjoy every berry on the bunch:
                            </p>
                                <ul>
                                    <li>
                                        Refrigerate grapes to maximize shelf life.
                                </li>
                                    <li>
                                        Store grapes unwashed. Rinse just before serving or adding to a recipe.
                                </li>
                                    <li>
                                        Grapes are indeed a berry, and tend to absorb odors. Keep them away from the more pungent fridge dwellers such as green onions or leeks.
                                </li>
                                </ul>
                                <h5>Put the Freeze on ’em</h5>
                                <p>
                                    It’s a little known fact, but grapes are delicious when frozen. Wash and dry fresh grapes, then lay them out on a tray and freeze them. Once they’re good and frozen, transfer them to an airtight container and store in the freezer. These frozen berries will be ready to be enjoyed for up to three months. Eat them right out the freezer, but don’t thaw them again, they won’t have much snap.
                            </p>
                                <ul className="actions">
                                    <li><Link to="/#table-grapes" className="button">Back</Link></li>
                                </ul>
                            </div>
                        </div>
                    </section>
                </section>
            </div>

        </Layout>
    )
}

export default TableGrapes